import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PasswordsMixin extends Vue {
  forgotPassword(data: any, tag: string) {
    return this.$httpPost(
      BASE_API_URL+"password/forgot",
      tag,
      data
    );
  }
  resetPassword(data: any, tag: string) {
    return this.$httpPost(
      BASE_API_URL+"password/reset",
      tag,
      data
    );
  }
}
