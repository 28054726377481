


























































import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import PasswordsMixin from "@/mixins/http/PasswordsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import { RequestData } from "@/store/types/RequestStore";
import { RegisterHttp } from "@/utils/Decorators";
import { showErrorToast, showToast } from "@/utils/Toast";
import Credits from "@/components/Credits.vue";
import { easync } from "@/utils/http";

@Component({ components: { DisplayErrors, Credits } })
export default class ForgotPassword extends Mixins(PasswordsMixin) {
  private email = "";

  @RegisterHttp("forgot") http: RequestData;

  get emailValid() {
    const emailRegExp = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    return emailRegExp.test(this.email);
  }

  async submit() {
    const [data, errors] = await easync(
      this.forgotPassword({ email: this.email }, "forgot")
    );

    if (errors) {
      showErrorToast("C'è stato un errore con l'invio della mail. Controlla che l'indirizzo sia corretto");
      return;
    }

    showToast(`Email inviata! Controlla la tua mail`);
  }
}
